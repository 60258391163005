@import '~src/css/quasar.variables.sass', 'quasar/src/css/variables.sass'

#blogPost h1
  font-weight: 700!important
  font-size: 32px!important
  line-height: 42px!important
  color: $grey-7!important

#blogPost h2
  font-weight: 400!important
  font-size: 18px!important
  line-height: 32px!important
  color: $grey-6!important

#blogPost h3
  font-weight: 500!important
  font-size: 24px!important
  line-height: 32px!important
  scroll-margin-top: 90px
  color: $grey-7!important

#blogPost h4, #blogPost h5, #blogPost h6
  font-weight: 500!important
  font-size: 18px!important
  line-height: 32px!important
  scroll-margin-top: 90px
  color: $grey-7!important
  margin: 2em auto!important

#blogPost b
  font-weight: 500!important

#blogPost img
  max-width: 100%!important
